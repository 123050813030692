<template>
  <div>
    <v-sheet
      v-show="!loading"
      class="pa-1"
    >
      <v-row class="justify-start no-gutters align-center mx-2">
        <v-select
          class="small-select mr-2 flex-md-grow-0"
          outlined="outlined"
          dense="dense"
          hide-details="hide-details"
          color="primary"
          :items="examOptions"
          :value="exam"
          @change="updateSelectedExam"
        />
        <span class="body-2 flex-grow-1">の回答済み問題の直近回答の正答率を確認</span>
      </v-row>
      <result-chart
        :questions="questions"
        @selectedCategory="filterCategory"
      />
      <v-card class="ma-3">
        <v-data-table
          class="custom-dt-header"
          :headers="headers"
          :items="questions"
          :search="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          multi-sort="multi-sort"
          dense="dense"
          @click:row="showStatementModal"
        >
          <template v-slot:item.noFlg="{item}">
            <v-icon
              :color="item.noFlg ? 'grey' : 'green'"
              @click.prevent="toggleBookmark(item)"
            >
              mdi-bookmark-plus
            </v-icon>
          </template>
          <template v-slot:item.exclude_flg="{item}">
            <v-icon
              :color="item.exclude_flg ? 'red' : 'grey'"
              @click.prevent="toggleExcludeFlg(item)"
            >
              mdi-cancel
            </v-icon>
          </template>
          <template v-slot:item.noMemoFlg="{item}">
            <v-icon
              :color="item.noMemoFlg ? 'grey' : 'green'"
              size="22px"
            >
              mdi-file-edit
            </v-icon>
          </template>
          <template v-slot:item.ronbun_flg="{item}">
            {{ item.ronbun_flg ? '論文式' : '短答式' }}
          </template>
          <template v-slot:item.last_answer="{item}">
            <v-icon
              v-if="item.last_answer === true"
              color="green"
            >
              mdi-circle-outline
            </v-icon>
            <v-icon
              v-else-if="item.last_answer === false"
              color="red"
            >
              mdi-close
            </v-icon>
            <v-icon
              v-else
              color="grey"
            >
              mdi-minus
            </v-icon>
          </template>
          <template v-slot:item.correctHistory="{item}">
            <v-row class="no-gutters">
              <correct-history-icon :correct="item.last_correct" />
              <correct-history-icon :correct="item.second_to_last_correct" />
              <correct-history-icon :correct="item.third_to_last_correct" />
            </v-row>
          </template>
          <template v-slot:item.headline="{item}">
            <span style="white-space: pre-wrap;">{{ item.headline }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-sheet>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate="indeterminate"
        size="64"
      />
    </v-overlay>
    <v-snackbar
      v-model="showApiFailedBar"
      bottom="bottom"
      color="error"
    >
      {{ apiFailedMessage }}
      <template v-slot:action="{ attrs }">
        <v-icon
          v-bind="attrs"
          dark="dark"
          @click="showApiFailedBar = false"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="showToggleFlgBar"
      bottom="bottom"
      :color="toggleFlgBarColor"
    >
      {{ toggleFlgBarMessage }}
      <template v-slot:action="{ attrs }">
        <v-icon
          v-bind="attrs"
          dark="dark"
          @click="showToggleFlgBar = false"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
    <question-statement
      :open.sync="openQuestionStatement"
      :question="selectedQuestion"
      @memoFlg="setMemoFlg"
    />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from 'vuex';
import ResultChart from "@/components/Result/ResultChart";
import CorrectHistoryIcon from "@/components/Result/CorrectHistoryIcon.vue";
import QuestionStatement from "@/components/Result/QuestionStatement.vue";
import dayjs from "dayjs";

export default {
  name: "PastResult",
  components: { QuestionStatement, ResultChart, CorrectHistoryIcon },
  data() {
    return {
      tab: null,
      loading: false,
      showApiFailedBar: false,
      apiFailedMessage: '',
      showToggleFlgBar: false,
      toggleFlgBarColor: 'green', // green | grey
      toggleFlgBarMessage: '',
      showFlgOnBar: false,
      showFlgOffBar: false,
      questions: [],
      search: '',
      openQuestionStatement: false,
      questionId: undefined,
      selectedQuestion: undefined,
      disableShowStatement: false,
      sortBy: ['updated_at'],
      sortDesc: [true],
      examOptions: [
        { text: '2023年目標（2023/2024年目標）', value: '2023' },
        { text: '2024年目標（2024/2025年目標）', value: '2024' },
        { text: '2025年目標（2025/2026年目標）', value: '2025' },
      ]
    }
  },
  computed: {
    ...mapGetters(["exam"]),
    headers() {
      return [
        { text: '回答日', value: "updated_at" },
        { text: 'ブックマーク', value: "noFlg" },
        { text: '出題除外', value: "exclude_flg" },
        { text: 'メモ', value: "noMemoFlg" },
        { text: '正解(直近)', value: "last_answer" },
        { text: '短答/論文', value: "ronbun_flg" },
        { text: '科目', value: "level0" },
        { text: '章', value: "level1" },
        { text: '見出し', value: "headline" },
        { text: '問題番号', value: "question_num" },
        { text: '直近3回の正解', value: "correctHistory" },
      ]
    }
  },
  created() {
    this.fetchResults()
  },
  methods: {
    async fetchResults() {
      this.loading = true;
      const url = `api/v1/users/results?exam=${this.exam}`
      await axios.get(url).then((res) => {
        this.questions = res.data['single_questions'];
        this.questions = this.questions.map((question) => {
          const headline = [question.level2, question.level3, question.level4].filter(l => !!l).join("\n");
          const correctHistory = [question.last_correct, question.second_to_last_correct, question.third_to_last_correct].filter(l => !!l).length;
          const noMemoFlg = question.memo === null || question.memo === ''
          const updated_at = dayjs(question.updated_at).format('YYYY/MM/DD')
          return { ...question, updated_at, headline, correctHistory, noMemoFlg, noFlg: !question.flg }
        })
      }).catch(() => {
        this.showApiFailedBar = true;
        this.apiFailedMessage = 'データの取得に失敗しました、再度お試しください';
      }).finally(() => {
        this.loading = false;
      });
    },
    updateSelectedExam(e) {
      this.$store.dispatch('updateSelectedConditions', { exam: e })
      this.fetchResults()
    },
    async toggleBookmark(question) {
      this.disableShowStatement = true
      question.noFlg = !question.noFlg
      const body = { question_id: question.id, flg: !question.noFlg, }
      try {
        await axios.post('api/v1/users/bookmark', body)
        this.showToggleFlgBar = true
        this.toggleFlgBarColor = question.noFlg ? 'grey' : 'green'
        this.toggleFlgBarMessage = question.noFlg ? 'ブックマークを外しました' : 'ブックマークしました'
      } catch (e) {
        this.showToggleFlgBar = false
        this.showApiFailedBar = true
        this.apiFailedMessage = e.response.data.body
        question.noFlg = !question.noFlg
      }
      this.disableShowStatement = false
    },
    async toggleExcludeFlg(question) {
      this.disableShowStatement = true
      question.exclude_flg = !question.exclude_flg
      const body = { question_id: question.id, exclude_flg: question.exclude_flg, }
      try {
        await axios.post('api/v1/users/exclude_flg', body)
        this.showToggleFlgBar = true
        this.toggleFlgBarColor = question.exclude_flg ? 'green' : 'grey'
        this.toggleFlgBarMessage = question.exclude_flg ? '出題除外にしました' : '出題除外を解除しました'
      } catch (e) {
        this.showToggleFlgBar = false
        this.showApiFailedBar = true
        this.apiFailedMessage = e.response.data.body
        question.exclude_flg = !question.exclude_flg
      }
      this.disableShowStatement = false
    },
    setMemoFlg(questionId, flg) {
      const question = this.questions.find(q => q.id === questionId)
      question.noMemoFlg = !flg
    },
    filterCategory(value) {
      this.search = value
    },
    showStatementModal(row) {
      if (this.disableShowStatement) return;

      this.questionId = row.id
      this.selectedQuestion = row
      this.openQuestionStatement = true
    },
  }
}
</script>

<style scoped>
.custom-dt-header .v-data-table-header-mobile .v-data-table-header__icon {
  opacity: 1 !important;
}

.small-select {
  font-size: 0.8rem;
}
</style>
